import Badge from "components/Badge/Badge";
import Image from "components/Image";
import { TaxonomyType } from "data/types";
import { FC } from "react";
import kips from "../../images/kips.png";

export interface CardCategory4Props {
    className?: string;
    taxonomy?: TaxonomyType;
    image?: any;
    name?: string;
    data?: any;
    index?: string;
}

const CardCategory4: FC<CardCategory4Props> = ({ className = "", taxonomy, index, image, name, data }) => {
    const singleImage = image.map((item: any) => item.thumbnail);
    const startAppUrl = process.env.REACT_APP_START_URL;

    return (
        <div className={`nc-CardCategory4 flex flex-col ${className}`}>
            <div className="flex-shrink-0 relative w-full aspect-w-7 aspect-h-5 h-0 rounded-3xl overflow-hidden group">
                <Image
                    alt="taxonomies"
                    fill
                    src={singleImage[0] || ""}
                    className="object-cover w-full h-full rounded-2xl"
                    sizes="(min-width: 1024px) 20rem, (min-width: 640px) 16rem, 12rem"
                />
                <div>{index && <Badge color={"yellow"} name={index} className="absolute top-3 left-3" />}</div>
                <span className="opacity-0 group-hover:opacity-100 absolute inset-0 bg-black bg-opacity-10 transition-opacity"></span>
            </div>

            <div className="flex items-center mt-5">
                <Image src={kips} className={`w-9 h-9 bg-yellow-500 rounded-full`}></Image>
                <div className="ml-4">
                    <h2 className="text-base text-neutral-900 dark:text-neutral-100 font-medium">{name}</h2>
                    <span className="block text-sm text-neutral-500 dark:text-neutral-400"></span>
                </div>
            </div>
        </div>
    );
};

export default CardCategory4;
