import CardCategory7 from 'components/CardCategory7/CardCategory7';
import Heading from 'components/Heading/Heading';
import MySlider from 'components/MySlider';
import { Category } from 'framework/rest/types';
import { FC } from 'react';

export interface SectionSliderNewCategories2Props {
    className?: string;
    heading: string;
    subHeading: string;
    categories: Category[];
    itemPerRow?: number;
}

const SectionSliderNewCategories2: FC<SectionSliderNewCategories2Props> = ({
    heading = 'Suggestions for discovery',
    subHeading = 'Popular places to recommends for you',
    className = '',
    categories,
    itemPerRow = 5,
}) => {
    return (
        <div className={`nc-SectionSliderNewAuthors ${className}`}>
            <Heading
                desc={subHeading}
                isCenter
            >
                {heading}
            </Heading>
            <MySlider
                itemPerRow={itemPerRow}
                data={categories}
                renderItem={(item, index) => (
                    <CardCategory7
                        key={index}
                        category={item}
                    />
                )}
            />
        </div>
    );
};

export default SectionSliderNewCategories2;
