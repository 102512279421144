
import { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { Attachment } from "framework/rest/types";
import { useUploadMutation } from "framework/rest/upload/use-upload.mutation";

const getPreviewImage = (value: any) => {
	let images: any[] = [];
	if (value) {
		images = Array.isArray(value) ? value : [{ ...value }];
	}
	return images;
};
export default function Uploader({ onChange, value, multiple }: any) {
	const [files, setFiles] = useState<Attachment[]>(getPreviewImage(value));
	const { mutate: upload, isLoading: loading } = useUploadMutation();
	const { getRootProps, getInputProps } = useDropzone({
		accept: {'image/*': []},
		multiple,
		onDrop: async (acceptedFiles) => {
			if (acceptedFiles.length) {
				upload(
					acceptedFiles, // it will be an array of uploaded attachments
					{
						onSuccess: (data) => {
							let mergedData;
							if (multiple) {
								mergedData = files.concat(data);
								setFiles(files.concat(data));
							} else {
								mergedData = data[0];
								setFiles(data);
							}
							if (onChange) {
								onChange(mergedData);
							}
						},
					}
				);
			}
		},
	});

	const handleDelete = (image: string) => {
		const images = files.filter((file) => file.thumbnail !== image);

		setFiles(images);
		if (onChange) {
			onChange(images);
		}
	};
	const thumbs = files?.map((file: any, idx) => {
		if (file.id) {
			return (
				<div
					className="inline-flex flex-col overflow-hidden border border-border-200 rounded mt-2 me-2 relative"
					key={idx}
				>
					<div className="flex items-center justify-center min-w-0 w-16 h-16 overflow-hidden bg-gray-300">
						<img src={file.original} />
					</div>
					{multiple ? (
						<button
							className="w-4 h-4 flex items-center justify-center rounded-full bg-red-600 text-xs text-light absolute top-1 end-1 shadow-xl outline-none"
							onClick={() => handleDelete(file.thumbnail)}
						>

							<svg
								className="w-3 h-3 text-light"
								stroke="currentColor"
								fill="none"
								viewBox="0 0 24 24"
							>
								<path
									strokeLinecap="round"
									strokeLinejoin="round"
									strokeWidth={2}
									d="M6 18L18 6M6 6l12 12"
								/>
							</svg>
						</button>
					) : null}
				</div>
			);
		}
	});

	useEffect(
		() => () => {
			// Make sure to revoke the data uris to avoid memory leaks
			files.forEach((file: any) => URL.revokeObjectURL(file.thumbnail));
		},
		[files]
	);

	return (
		<section className="upload">
			<div
				{...getRootProps({
					className:
						"border-dashed border-2 border-border-base h-36 rounded flex flex-col justify-center items-center cursor-pointer focus:border-gray-500 focus:outline-none",
				})}
				style={{position: "relative"}}
			>
				{loading && <div style={{position: "absolute", left: 0, width: "100%", backgroundColor: "white"}}><div style={{textAlign: "center"}} className="p-10">loading...</div></div>}
				<input {...getInputProps()} />
				<svg
                    className="mx-auto h-12 w-12 text-neutral-400"
                    stroke="currentColor"
                    fill="none"
                    viewBox="0 0 48 48"
                    aria-hidden="true"
                  >
                    <path
                      d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></path>
                  </svg>
				<p className="text-body text-sm mt-4 text-center">
					<span className="text-accent font-semibold">
						sürükle ve bırak
					</span>{" "}
					Yükle <br />
					<span className="text-xs text-body">
						veya göz atmak için tıklayın
					</span>
				</p>
			</div>

			{(!!thumbs.length || loading) && (
				<aside className="flex flex-wrap mt-2">
					{!!thumbs.length && thumbs}
				</aside>
			)}
		</section>
	);
}
