import { useAtom } from 'jotai';
import { FC } from 'react';
import { AiOutlineYoutube } from 'react-icons/ai';
import { FaFacebookF, FaInstagram } from 'react-icons/fa';
import { FaLinkedinIn, FaXTwitter } from 'react-icons/fa6';
import { generalSettingsAtom } from 'store/atom/general-settings';

export interface SocialsList1Props {
    className?: string;
}

export interface SocialType {
    id: string;
    name: string;
    icon: string;
}

const logos = {
    FacebookIcon: <FaFacebookF size={20} />,
    TwitterIcon: <FaXTwitter size={20} />,
    InstagramIcon: <FaInstagram size={20} />,
    YouTubeIcon: <AiOutlineYoutube size={20} />,
    LinkedinIcon: <FaLinkedinIn size={20} />,
};

const SocialsList1: FC<SocialsList1Props> = ({ className = 'space-y-2.5' }) => {
    const [generalSettings] = useAtom(generalSettingsAtom);
    const { socials: socialsData } = generalSettings?.contactDetails || {};

    const renderItem = (item: any, index: number) => {
        const logo = logos[item.icon as keyof typeof logos];
        return (
            <a
                href={item.url}
                className="flex items-center text-2xl text-neutral-700 hover:text-black dark:text-neutral-300 dark:hover:text-white leading-none space-x-3 gap-2"
                key={index}
                target="_blank"
            >
                <div>{logo}</div>
                <span className="hidden lg:block text-sm">{item.label}</span>
            </a>
        );
    };

    return (
        <div
            className={`nc-SocialsList1 flex gap-2 ${className}`}
            data-nc-id="SocialsList1"
        >
            {socialsData?.map(renderItem)}
        </div>
    );
};

export default SocialsList1;
