import PostCardCommentBtn from 'components/PostCardCommentBtn/PostCardCommentBtn';
import PostCardLikeAction from 'components/PostCardLikeAction/PostCardLikeAction';
import { FC } from 'react';

export interface PostCardLikeAndCommentProps {
    className?: string;
    itemClass?: string;
    hiddenCommentOnMobile?: boolean;
    useOnSinglePage?: boolean;
}

const PostCardLikeAndComment: FC<PostCardLikeAndCommentProps> = ({
    className = '',
    itemClass = 'px-3 h-8 text-xs',
    hiddenCommentOnMobile = true,
    useOnSinglePage = false,
}) => {
    return (
        <div className={`nc-PostCardLikeAndComment flex items-center space-x-2 ${className}`}>
            <PostCardCommentBtn
                className={`${hiddenCommentOnMobile ? 'hidden sm:flex' : 'flex'}  ${itemClass}`}
                isATagOnSingle={useOnSinglePage}
            />
            <PostCardLikeAction className={itemClass} />
        </div>
    );
};

export default PostCardLikeAndComment;
