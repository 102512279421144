import {
    CreateSubscribeInput
} from "framework/rest/types";
import { useQuery } from "react-query";
import { CoreApi } from "utils/core-api";
import { API_ENDPOINTS } from "utils/endpoints";

const SubscribeService = new CoreApi(API_ENDPOINTS.SUBSCRIBE);

export const createSubscribe = async (requestData: CreateSubscribeInput) => {
    const { data } = await SubscribeService.create(requestData);

    return data;
};

const useCreateSubscribe = (data: CreateSubscribeInput) => {
    return useQuery([API_ENDPOINTS.SUBSCRIBE, data], async () => await createSubscribe(data), {
        enabled: false
    })
}


export { useCreateSubscribe };

