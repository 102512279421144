import Avatar from 'components/Avatar/Avatar';
import Link from 'components/Link';
import { PostDataType } from 'data/types';
import moment from 'moment';
import 'moment/locale/tr';
import { FC } from 'react';
export interface CardAuthor2Props extends Pick<PostDataType, 'date' | 'author' | 'users' | 'user_profiles'> {
    className?: string;
    readingTime?: PostDataType['readingTime'];
    hoverReadingTime?: boolean;
    url?: string;
}

const CardAuthor2: FC<CardAuthor2Props> = ({
    className = '',
    users,
    user_profiles,
    readingTime,
    date,
    url,
    hoverReadingTime = true,
}) => {
    return (
        <Link
            href={url!}
            className={`nc-CardAuthor2 relative inline-flex items-center ${className}`}
        >
            <Avatar
                sizeClass="h-10 w-10 text-base"
                containerClassName="flex-shrink-0 mr-3"
                radius="rounded-full"
                imgUrl={user_profiles?.avatar?.original}
                userName={users?.name}
            />
            <div>
                <h2
                    className={`text-sm text-neutral-700 hover:text-black dark:text-neutral-300 dark:hover:text-white font-medium`}
                >
                    {users?.name}
                </h2>
                <span className={`flex items-center mt-1 text-xs text-neutral-500 dark:text-neutral-400`}>
                    <span>{moment(date).locale('tr').format('MMM DD, YYYY')}</span>
                    {readingTime && (
                        <>
                            <span
                                className={`hidden lg:inline mx-1 transition-opacity ${
                                    hoverReadingTime ? 'opacity-0 group-hover:opacity-100' : ''
                                }`}
                            >
                                ·
                            </span>
                            <span
                                className={`hidden lg:inline transition-opacity ${
                                    hoverReadingTime ? 'opacity-0 group-hover:opacity-100' : ''
                                }`}
                            >
                                {readingTime} min read
                            </span>
                        </>
                    )}
                </span>
            </div>
        </Link>
    );
};

export default CardAuthor2;
