import Base from "utils/base";
import {CreatePost, UpdatePost} from "framework/rest/types";

class Post extends Base<CreatePost, UpdatePost> {
        fetchParent = async (url: string) => {
            return this.http(url, "get");
        };
    }

const postInstance = new Post();
export default postInstance;
