import { ArrowRightIcon } from "@heroicons/react/24/outline";
import Avatar from "components/Avatar/Avatar";
import Link from "components/Link";
import NcImage from "components/NcImage/NcImage";
import { Category } from "framework/rest/types";
import { FC } from "react";

export interface CardCategory7Props {
    className?: string;
    category: Category;
}

const CardCategory7: FC<CardCategory7Props> = ({ className = "", category }) => {
    const { image, banner_image, slug, name, details, products_count } = category;
    const url = `${process.env.REACT_APP_START_URL}?category=${slug}`;

    return (
        <Link
            href={url ?? ""}
            className={`nc-CardAuthorBox2 flex flex-col overflow-hidden bg-white dark:bg-neutral-800 rounded-3xl ${className}`}
        >
            <div className="relative flex-shrink-0 ">
                <div>
                    {image?.length! > 0 && (
                        <NcImage
                            alt="author"
                            containerClassName="flex aspect-w-7 aspect-h-5 w-full h-0"
                            src={image![0].original}
                            fill
                            sizes="(max-width: 600px) 480px, 33vw"
                        />
                    )}
                </div>
                <div className="absolute top-3 inset-x-3 flex">
                    <div className=" py-1 px-4 bg-neutral-100 dark:bg-neutral-800 rounded-full flex items-center justify-center leading-none text-xs font-medium">
                        {products_count} <ArrowRightIcon className="w-5 h-5 text-yellow-600 ml-3" />
                    </div>
                </div>
            </div>

            <div className="-mt-8 mx-4 my-8 text-center">
                <Avatar
                    containerClassName="ring-2 ring-white"
                    sizeClass="w-16 h-16 text-2xl"
                    radius="rounded-full"
                    imgUrl={banner_image?.thumbnail}
                    userName={name}
                />
                <div className="mt-3">
                    <h2 className={`text-base font-medium`}>
                        <span className="line-clamp-1">{name}</span>
                    </h2>
                    <span className={`block mt-1 text-sm text-neutral-500 dark:text-neutral-400`}>{details}</span>
                </div>
            </div>
        </Link>
    );
};

export default CardCategory7;
