import Badge from 'components/Badge/Badge';
import { PostDataType, TwMainColor } from 'data/types';
import { FC } from 'react';

export interface CategoryBadgeListProps {
    className?: string;
    itemClass?: string;
    categories: PostDataType['categories'];
}

const colorList: TwMainColor[] = ['pink', 'red', 'green', 'purple', 'indigo', 'yellow', 'blue'];

const CategoryBadgeList: FC<CategoryBadgeListProps> = ({
    className = 'flex flex-wrap space-x-2',
    itemClass,
    categories,
}) => {
    return (
        <div
            className={`nc-CategoryBadgeList ${className}`}
            data-nc-id="CategoryBadgeList"
        >
            {categories?.map((item: any, index) => (
                <Badge
                    className={itemClass}
                    key={index}
                    name={item?.name}
                    color={colorList[item?.id % colorList.length]}
                />
            ))}
        </div>
    );
};

export default CategoryBadgeList;
