import { useAtom } from "jotai";
import { QueryKey, useQuery, UseQueryOptions } from "react-query";
import { authorizationAtom } from "store/atom/auth";
import { CoreApi } from "utils/core-api";
import { API_ENDPOINTS } from "utils/endpoints";

const CustomerService = new CoreApi(API_ENDPOINTS.CUSTOMER);
export const fetchMe = async () => {
    const { data } = await CustomerService.findAll();
    return { me: data };
};

export const useCustomerQuery = (options: UseQueryOptions<any, Error, any, QueryKey>) => {
    return useQuery<any, Error>(API_ENDPOINTS.CUSTOMER, fetchMe, options);
};

const useUser = () => {
    const [isAuthorized] = useAtom(authorizationAtom);
    const { data, isLoading, error } = useCustomerQuery({
        enabled: isAuthorized,
        onError: (err) => {},
    });
    return { me: data?.me, loading: isLoading, error };
};

export default useUser;
