import CategoryBadgeList from "components/CategoryBadgeList/CategoryBadgeList";
import Image from "components/Image";
import Link from "components/Link";
import PostCardLikeAndComment from "components/PostCardLikeAndComment/PostCardLikeAndComment";
import PostCardSaveAction from "components/PostCardSaveAction/PostCardSaveAction";
import PostFeaturedMedia from "components/PostFeaturedMedia/PostFeaturedMedia";
import PostTypeFeaturedIcon from "components/PostTypeFeaturedIcon/PostTypeFeaturedIcon";
import { PostDataType } from "data/types";
import moment from "moment";
import { FC } from "react";

export interface Card9Props {
    className?: string;
    ratio?: string;
    post: PostDataType;
    hoverClass?: string;
}

const Card9: FC<Card9Props> = ({
    className = "h-full",
    ratio = "aspect-w-3 aspect-h-3 sm:aspect-h-4",
    post,
    hoverClass = "",
}) => {
    const { title, post_title, thumbnail_image, href, categories, date, type, id, users } = post;

    let url = "#";

    if (type === "textual") {
        url = `/post-textual/${id}`;
    } else if (type === "photo") {
        url = `/post-photo/${id}`;
    } else if (type === "video") {
        url = `/post-video/${id}`;
    }

    const renderMeta = () => {
        return (
            <div className="inline-flex items-center text-xs text-neutral-300">
                <div className="block ">
                    <h2 className="block text-base sm:text-lg font-semibold text-white ">
                        <span className="line-clamp-2" title={title ?? post_title}>
                            {title ?? post_title}
                        </span>
                    </h2>
                    <Link href={"#"} className="flex mt-2.5 relative">
                        <span className="block text-neutral-200 hover:text-white font-medium truncate">
                            {users?.name}
                        </span>
                        <span className="mx-[6px] font-medium">·</span>
                        <span className="font-normal truncate">{moment(date).format("MMM DD, YYYY")}</span>
                    </Link>
                </div>
            </div>
        );
    };

    return (
        <div
            className={`nc-Card9 relative flex flex-col group rounded-3xl overflow-hidden z-0 ${hoverClass} ${className}`}
        >
            <div className="absolute inset-x-0 top-0 p-3 flex items-center justify-between transition-all opacity-0 z-[-1] group-hover:opacity-100 group-hover:z-10 duration-300">
                <PostCardLikeAndComment className="relative" />
                <PostCardSaveAction hidenReadingTime className="relative" post={post} />
            </div>
            <div className={`flex items-start relative w-full ${ratio}`}></div>
            {type === "audio" ? (
                <div className="absolute inset-0">
                    <PostFeaturedMedia post={post} />
                </div>
            ) : (
                <Link href={url}>
                    <Image
                        fill
                        alt=""
                        className="object-cover w-full h-full rounded-3xl"
                        src={thumbnail_image?.original}
                        sizes="(max-width: 600px) 480px, 500px"
                    />
                    <PostTypeFeaturedIcon
                        className="absolute top-3 left-3 group-hover:hidden"
                        postType={type!}
                        wrapSize="w-7 h-7"
                        iconSize="w-4 h-4"
                    />
                    <span className="absolute inset-0 bg-black bg-opacity-10 opacity-0 group-hover:opacity-100 transition-opacity"></span>
                </Link>
            )}
            <Link
                href={href}
                className="absolute bottom-0 inset-x-0 h-1/2 bg-gradient-to-t from-black opacity-50"
            ></Link>
            <div className="absolute bottom-0 inset-x-0 p-4 flex flex-col flex-grow">
                <Link href={href} className="absolute inset-0"></Link>
                <div className="mb-3">
                    <CategoryBadgeList categories={categories} />
                </div>
                {renderMeta()}
            </div>
        </div>
    );
};

export default Card9;
