import {
  CategoriesQueryOptionsType,
  CategoryPaginator,
  QueryParamsType,
} from "framework/rest/types";
import { pickBy } from "lodash";
import { useQuery } from "react-query";
import { CoreApi } from "utils/core-api";
import { mapPaginatorData } from "utils/data-mappers";
import { API_ENDPOINTS } from "utils/endpoints";
import categoryInstance from "./category";

const CategoryService = new CoreApi(API_ENDPOINTS.CATEGORIES);
export const stringifySearchQuery = (values: any) => {
  const parsedValues = pickBy(values);
  return Object.keys(parsedValues)
    .map((k) => {
      if (k === "type") {
        return `${k}.slug:${parsedValues[k]};`;
      }
      if (k === "category") {
        return `categories.slug:${parsedValues[k]};`;
      }
      return `${k}:${parsedValues[k]};`;
    })
    .join("")
    .slice(0, -1);
};

const fetchCategories = async ({
  queryKey,
}: QueryParamsType): Promise<{ categories: CategoryPaginator }> => {
  const [, params] = queryKey as [string, CategoriesQueryOptionsType];

  const {
    page,
    text,
    type,
    limit = 15,
    orderBy = "created_at",
    sortedBy = "ASC",
    parent = null,
    featured = null
  } = params as CategoriesQueryOptionsType;

  const searchString = stringifySearchQuery({
    name: text,
    type,
  });
  // @ts-ignore
  const queryParams = new URLSearchParams({
    searchJoin: "and",
    orderBy,
    sortedBy,
    parent,
    limit: limit.toString(),
    featured,
    ...(page && { page: page.toString() }),
    ...(Boolean(searchString) && { search: searchString }),
  });
  const url = `${API_ENDPOINTS.CATEGORIES}?${queryParams.toString()}`;
  const {
    data: { data, ...rest },
  } = await categoryInstance.all(url);
  return {
    categories: {
      data,
      paginatorInfo: {
        ...mapPaginatorData({ ...rest }),
        count: rest.total,
        currentPage: rest.current_page,
        lastPage: rest.last_page,
        perPage: rest.per_page,
        total: rest.total,
      },
    },
  };
};

const useCategoriesQuery = (options: CategoriesQueryOptionsType) => {
  return useQuery<{ categories: CategoryPaginator }, Error>(
    [API_ENDPOINTS.CATEGORIES, options],
    fetchCategories,
    {
      keepPreviousData: true,
    }
  );
};

export const fetchCategory = async (params: string | number) => {
  const { data } = await CategoryService.findOne(params);

  return data;
};

export { fetchCategories, useCategoriesQuery };

