import Link from "components/Link";
import { FC } from "react";

interface TagProps {
    className?: string;
    tag: any;
    hideCount?: boolean;
}

const TagPost: FC<TagProps> = ({ className = "", tag, hideCount = false }) => {
    const url = `${process.env.REACT_APP_START_URL}/products/tags/${tag?.slug}`;

    return (
        <Link
            className={`nc-Tag inline-block bg-white hover:bg-neutral-50 text-sm text-neutral-600 dark:text-neutral-300 py-2 px-3 rounded-lg md:py-2.5 md:px-4 dark:bg-neutral-900 ${className}`}
            href={url}
        >
            {tag.name}
        </Link>
    );
};

export default TagPost;
