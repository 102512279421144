import {
  TagsQueryOptionsType,
  QueryParamsType,
  TagPaginator,
} from "framework/rest/types";
import { API_ENDPOINTS } from "utils/endpoints";
import { useQuery } from "react-query";
import { mapPaginatorData } from "utils/data-mappers";
import { CoreApi } from "utils/core-api";
import { stringifySearchQuery } from "../category/categories.query";
import tagInstance from "./tag";

const TagsService = new CoreApi(API_ENDPOINTS.TAGS);

const fetchTags = async ({
  queryKey,
}: QueryParamsType): Promise<{ tags: TagPaginator }> => {
  const [_key, params] = queryKey;

  const {
    page,
    text,
    type,
    limit = 15,
    orderBy = "updated_at",
    sortedBy = "DESC",
  } = params as TagsQueryOptionsType;

  const searchString = stringifySearchQuery({
    name: text,
    type,
  });
  const url = `${API_ENDPOINTS.TAGS}?search=${searchString}&searchJoin=and&limit=${limit}&page=${page}&orderBy=${orderBy}&sortedBy=${sortedBy}`;
  const {
    data: { data, ...rest },
  } = await tagInstance.all(url);
  return {
    tags: {
      data,
      paginatorInfo: {
        ...mapPaginatorData({ ...rest }),
        count: rest.total,
        currentPage: rest.current_page,
        lastPage: rest.last_page,
        perPage: rest.per_page,
        total: rest.total,
      },
    },
  };
};

const useTagsQuery = (options: TagsQueryOptionsType) => {
  return useQuery<{ tags: TagPaginator }, Error>(
    [API_ENDPOINTS.TAGS, options],
    fetchTags,
    {
      keepPreviousData: true,
    }
  );
};

export const fetchTag = async (params: string | number) => {
  const { data } = await TagsService.findOne(params);

  return data;
};

export { useTagsQuery, fetchTags };
