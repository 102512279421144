import { ChevronDoubleRightIcon } from '@heroicons/react/24/solid';
import ButtonPrimary from 'components/Button/ButtonPrimary';
import rightImgDemo from 'images/kips-anasayfa.png';
import { useAtom } from 'jotai';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { authorizationAtom } from 'store/atom/auth';
import Image from '../Image';

export interface SectionBecomeAnAuthorProps {
    className?: string;
    rightImg?: string;
}

const SectionBecomeAnAuthor: FC<SectionBecomeAnAuthorProps> = ({ className = '', rightImg = rightImgDemo }) => {
    const [authorize, setAuthorize] = useAtom(authorizationAtom);
    const navigate = useNavigate();
    function handleStart() {
        if (!authorize) {
            navigate('/login');
        } else {
            window.location.href = `${process.env.REACT_APP_START_URL}/explore`;
        }
    }

    return (
        <div className={`nc-SectionBecomeAnAuthor relative flex flex-col lg:flex-row items-center  ${className}`}>
            <div className="flex-shrink-0 mb-14 lg:mb-0 lg:mr-10 lg:w-2/5">
                <span className="text-xs uppercase tracking-wider font-medium text-neutral-400">
                    BİZİ TERCİH EDEN YÜZLERCE GİRİŞİMCİ ARASINDA YERİNİZİ ALIN
                </span>
                <h2 className="font-semibold text-3xl sm:text-4xl mt-3">14 GÜN ÜCRETSİZ</h2>
                <span className="block mt-8 text-neutral-500 dark:text-neutral-400">
                    Hiçbir ücret ödemeden hesabınızı kolayca oluşturun,
                </span>
                <span className="block mt-0 text-neutral-500 dark:text-neutral-400">
                    kodlama olmadan hayalinizdeki websitenizi anında yayınlayın
                </span>
                <ButtonPrimary
                    className="mt-8"
                    onClick={handleStart}
                >
                    <span>Hemen Sitenizi Açın</span>
                    <ChevronDoubleRightIcon className="h-6 w-6 ml-4" />
                </ButtonPrimary>
            </div>
            <div className="flex-grow">
                <Image
                    alt="hero"
                    sizes="(max-width: 768px) 100vw, 50vw"
                    src={rightImg}
                />
            </div>
        </div>
    );
};

export default SectionBecomeAnAuthor;
