import { CheckIcon } from '@heroicons/react/24/solid';
import ButtonPrimary from 'components/Button/ButtonPrimary';
import ButtonSecondary from 'components/Button/ButtonSecondary';
import Heading2 from 'components/Heading/Heading2';
import { Link } from 'react-router-dom';
import Layout from '../layout';

export interface PricingItem {
    isActive: boolean;
    isPopular: boolean;
    name: string;
    pricing: string;
    desc: string;
    per: string;
    features: string[];
}

const pricings: PricingItem[] = [
    {
        isActive: true,
        isPopular: false,
        name: 'TANITIM',
        pricing: '$24',
        per: '/Ay',
        features: ['Avukat & Danışmanlık', 'Emlak & Gayrimenkul', 'Startup & Girişim'],
        desc: ` KIPS altyapısı ile işinizi dijital dünyaya taşıyın`,
    },
    {
        isActive: true,
        isPopular: true,
        name: 'E-TİCARET',
        pricing: '$39',
        per: '/Ay',
        features: ['Butik & Kişisel Marka', 'Toptan & Pekarende', 'Fabrika Sipariş Sistemi', 'Seyahat & Turizm'],
        desc: ` KIPS altyapısıyla hemen satış yapmaya başlayın`,
    },
    {
        isActive: false,
        isPopular: false,
        name: 'E-İHRACAT',
        pricing: '$79',
        per: '/Ay',
        features: ['E-İhracat Araçları', 'Uluslararası Kargo', 'Ülke Bazlı Pazarlama', 'Özel Entegrasyonlar'],
        desc: ` KIPS altyapısı ile siz de tüm dünyaya satış yapın`,
    },
];

const PageSubcription = () => {
    const renderPricingItem = (pricing: PricingItem, index: number) => {
        return (
            <div
                key={index}
                className={`h-full relative px-6 py-8 rounded-3xl border-2 flex flex-col overflow-hidden ${
                    pricing.isPopular ? 'border-primary-500' : 'border-neutral-100 dark:border-neutral-700'
                }`}
            >
                {pricing.isPopular && (
                    <span className="bg-primary-500 text-white px-3 py-1 tracking-widest text-xs absolute right-3 top-3 rounded-full z-10">
                        POPÜLER
                    </span>
                )}
                <div className="mb-8">
                    <h3 className="block text-sm uppercase tracking-widest text-neutral-6000 dark:text-neutral-300 mb-2 font-medium">
                        {pricing.name}
                    </h3>
                    <h2 className="text-5xl leading-none flex items-center text-neutral-700 dark:text-neutral-300">
                        <span>{pricing.pricing}</span>
                        <span className="text-lg ml-1 font-normal text-neutral-500 dark:text-neutral-300">
                            {pricing.per}
                        </span>
                    </h2>
                </div>
                <nav className="space-y-4 mb-8">
                    {pricing.features.map((item, index) => (
                        <li
                            className="flex items-center"
                            key={index}
                        >
                            <span className="mr-4 inline-flex flex-shrink-0 text-primary-6000">
                                <CheckIcon
                                    className="w-5 h-5"
                                    aria-hidden="true"
                                />
                            </span>
                            <span className="text-neutral-700 dark:text-neutral-300">{item}</span>
                        </li>
                    ))}
                </nav>
                <div className="flex flex-col mt-auto">
                    {pricing.isPopular ? (
                        <Link
                            to={`${process.env.REACT_APP_START_URL}/explore`}
                            className="flex"
                        >
                            <ButtonPrimary className="w-full">HEMEN BAŞLA</ButtonPrimary>
                        </Link>
                    ) : pricing.isActive ? (
                        <Link
                            to={`${process.env.REACT_APP_START_URL}/explore`}
                            className="flex"
                        >
                            <ButtonSecondary className="w-full">
                                <span className="font-medium">HEMEN BAŞLA</span>
                            </ButtonSecondary>
                        </Link>
                    ) : (
                        <ButtonSecondary
                            className="w-full"
                            disabled
                        >
                            <span className="font-medium">ÇOK YAKINDA!</span>
                        </ButtonSecondary>
                    )}
                    <p className="text-xs text-neutral-500 dark:text-neutral-400 mt-3">{pricing.desc}</p>
                </div>
            </div>
        );
    };

    return (
        <Layout>
            <header className="text-center max-w-2xl mx-auto - mb-14 sm:mb-16 lg:mb-20">
                <Heading2 emoji="">Fiyatlandırma</Heading2>
                <span className="block text-sm mt-2 text-neutral-700 sm:text-base dark:text-neutral-200">
                    Teknik bilgi gerekmeden, KIPS altyapısıyla sitenizi kolayca yayınlayın!
                </span>
            </header>

            <section className="text-neutral-600 text-sm md:text-base overflow-hidden">
                <div className="grid lg:grid-cols-3 gap-5 xl:gap-8">{pricings.map(renderPricingItem)}</div>
            </section>
        </Layout>
    );
};

export default PageSubcription;
