import Link from "components/Link";
import NcImage from "components/NcImage/NcImage";
import { TaxonomyType } from "data/types";
import { FC } from "react";

export interface CardCategory1Props {
    className?: string;
    taxonomy?: TaxonomyType;
    size?: "large" | "normal";
    image?: any;
    name?: string;
    data?: any;
}

const CardCategory1: FC<CardCategory1Props> = ({ className = "", size = "normal", taxonomy, image, name, data }) => {
    const singleImage = image.map((item: any) => item.thumbnail);
    const url = `${process.env.REACT_APP_START_URL}?category=${data?.slug}`;

    return (
        <Link href={url || "/"} className={`nc-CardCategory1 flex items-center ${className}`}>
            <NcImage
                alt=""
                containerClassName={`relative flex-shrink-0 ${
                    size === "large" ? "w-20 h-20" : "w-12 h-12"
                } rounded-lg mr-4 overflow-hidden`}
                src={singleImage[0] || ""}
                fill
                className="object-cover"
                sizes="80px"
            />
            <div>
                <h2
                    className={`${
                        size === "large" ? "text-lg" : "text-base"
                    } nc-card-title text-neutral-900 dark:text-neutral-100 text-sm sm:text-base font-medium sm:font-semibold`}
                >
                    {name}
                </h2>
                <span
                    className={`${
                        size === "large" ? "text-sm" : "text-xs"
                    } block mt-[2px] text-neutral-500 dark:text-neutral-400`}
                >
                    {/* {count} Articles */}
                </span>
            </div>
        </Link>
    );
};

export default CardCategory1;
