import { useQuery } from "react-query";
import { CoreApi } from "utils/core-api";
import { API_ENDPOINTS } from "utils/endpoints";

const MenusService = new CoreApi(API_ENDPOINTS.MENU);

export const fetchSettings = async () => {
  const data = await MenusService.findAll();
  return data;
};
// type SettingsResponse = {
//   settings: SettingsType;
// };
//ts-ignore
export const useMenuQuery = () => {
  return useQuery(API_ENDPOINTS.MENU, fetchSettings);
};
