export const API_ENDPOINTS = {
  CATEGORIES: "categories",
  PRODUCTS: "products",
  POPULAR_PRODUCTS: "popular-products",
  SETTINGS: "settings",
  CUSTOMER: "me",
  COUPONS: "coupons",
  PARENT_CATEGORIES: "categories",
  FEATURED_CATEGORIES: "featured-categories",
  TYPE: "types",
  UPLOAD: "attachments",
  ORDER: "orders",
  ORDER_STATUS: "order-status",
  LOGIN: "token",
  SOCIAL_LOGIN: "social-login-token",
  REGISTER: "register",
  FORGET_PASSWORD: "forget-password",
  LOGOUT: "logout",
  CHANGE_PASSWORD: "change-password",
  RESET_PASSWORD: "reset-password",
  VERIFY_FORGET_PASSWORD: "verify-forget-password-token",
  VERIFY_CHECKOUT: "orders/checkout/verify",
  CONTACT: "contact-us",
  ADDRESS: "address",
  ATTRIBUTES: "attributes",
  SHOPS: "shops",
  ORDERS: "orders",
  SEND_OTP_CODE: "send-otp-code",
  VERIFY_OTP_CODE: "verify-otp-code",
  OTP_LOGIN: "otp-login",
  UPDATE_CONTACT: "update-contact",
  CUSTOMERS: "users",
  TAGS: "tags",
  MENU: "menus?parent=null",
  MEGA_MENU: "menus?parent=null",
  HOME_BANNERS: "homepage-banners",
  SELECTED_BANNER: "selected-banners",
  SIDE_BAR_BANNERS: "sidebar-banners",
  FOOTER_BANNERS: "footer-banners",
  MOBILE_MENU: "menus?parent=null",
  SEARCH: "search",
  CUSTOMER_INFO: "customer-info",
  SITE_SETTINGS: "site-settings",
  USERS: "users",
  ME: "me",
  ATTACHMENTS: "attachments",
  SUBMIT_POST: "posts",
  GET_POST: "posts",
  SUBSCRIBE: "subscribe"
};
