import Base from "utils/base";
import { CreateCategory, UpdateCategory } from "framework/rest/types";

class Category extends Base<CreateCategory, UpdateCategory> {
        fetchParent = async (url: string) => {
            return this.http(url, "get");
        };
    }
    
const categoryInstance = new Category();
export default categoryInstance;
  