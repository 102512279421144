import Tag from 'components/Tag/Tag';
import WidgetHeading1 from 'components/WidgetHeading1/WidgetHeading1';
import { TaxonomyType } from 'data/types';
import { useTagsQuery } from 'framework/rest/tags/tag.query';
import { FC } from 'react';
export interface WidgetTagsProps {
    className?: string;
    tags?: TaxonomyType[];
}

const WidgetTags: FC<WidgetTagsProps> = ({ className = 'bg-neutral-100 dark:bg-neutral-800', tags = [] }) => {
    const { data, isLoading } = useTagsQuery({
        limit: 9,
    });

    const renderTags = () => {
        return data?.tags.data
            .filter(x => x.slug !== 'references' && x.slug !== 'features' && x.slug !== 'we-in-the-press')
            .map(tag => (
                <Tag
                    className="mr-2 mb-2"
                    key={tag.id}
                    tag={tag}
                />
            ));
    };

    if (isLoading) return <div>Loading...</div>;

    return (
        <div className={`nc-WidgetTags rounded-3xl overflow-hidden ${className}`}>
            <WidgetHeading1
                title="Sektörel Tasarımlar"
                viewAll={{ label: '', href: '/#' }}
            />
            <div className="flex flex-wrap p-4 xl:p-5">{renderTags()}</div>
        </div>
    );
};

export default WidgetTags;
